<template>
	<validation-observer
		#default="{ reset }"
		tag="div"
		class="cassie-vertical-md"
	>
		<SectionCard>
			<template #title>
				Link Fields
				<v-tooltip
					right
				>
					<template #activator="{ on }">
						<v-icon
							right
							v-on="on"
							@click.stop="linkFieldsModalOpen = true"
						>
							mdi-information
						</v-icon>
					</template>
					<span>
						Click to expand
					</span>
				</v-tooltip>
			</template>
			<template #title-action>
				<TextButton
					v-if="userFullPermissions"
					@click="addLinkField"
				>
					<v-icon>
						mdi-plus
					</v-icon>
					Add Field
				</TextButton>
			</template>
			<template #body>
				<p>
					Select the field in Cassie's database that you wish to store information (that will be passed in as part of the {{ linkTypeName }}) about the data subject in,
					and whether the information should be used to identify if the data subject already exists in Cassie or not.
				</p>
				<v-simple-table	dense>
					<template #default>
						<thead>
							<tr>
								<th
									scope="col"
									class="text-left"
								>
									Identification Field
								</th>
								<th
									scope="col"
									class="text-left"
								>
									Database Field Name *
								</th>
								<th
									scope="col"
									class="text-left"
								>
									Name in Link *
								</th>
								<th
									scope="col"
									class="text-left"
								>
									Replace value in link *
								</th>
								<th
									scope="col"
									class="text-left"
								>
									Preview value
								</th>
								<th
									scope="col"
									class="text-left"
								>
									Encrypted
								</th>
								<th
									scope="col"
									class="text-left"
								>
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(linkField, index) in (linkFields || [])"
								:key="linkField.standdakFieldName"
							>
								<td class="text-left">
									<validation-provider
										#default="{ errors }"
										:rules="{
											arrayIsNotEmpty: {
												value: linkFields ? linkFields.filter(({ isMatchField }) => isMatchField) : [],
												ignoreVModel: true
											}
										}"
										mode="passive"
									>
										<v-switch
											:input-value="Boolean(linkField.isMatchField)"
											:error-messages="errors"
											dense
											:hide-details="!errors.length"
											class="ma-0"
											:disabled="!userFullPermissions"
											@change="updateLinkFieldsRow(index, 'isMatchField', $event)"
										/>
									</validation-provider>
								</td>
								<td class="text-left">
									<Dropdown
										:value="linkField.standdakFieldName"
										:items="availableFields"
										custom-sort
										:disabled="!userFullPermissions"
										:label="`Database field name ${index+1}`"
										searchable
										small
										rules="required"
										@input="updateLinkFieldsRow(index, 'standdakFieldName', $event)"
									/>
								</td>
								<td
									class="text-left"
								>
									<TextField
										:value="linkField.friendlyNameInLink"
										:disabled="!userFullPermissions"
										:label="`Name in link ${index+1}`"
										small
										:rules="{required: true, max: 255, inUseLinkFieldNames: usedNameInLinkFields(linkField)}"
										@input="updateLinkFieldsRow(index, 'friendlyNameInLink', $event)"
									/>
								</td>
								<td
									class="text-left"
								>
									<TextField
										:value="linkField.replaceValueInLink"
										:disabled="!userFullPermissions"
										:label="`Replace value in link ${index+1}`"
										small
										:rules="{required: true, max: 64}"
										@input="updateLinkFieldsRow(index, 'replaceValueInLink', $event)"
									/>
								</td>
								<td>
									<TextField
										:value="linkField.previewValue"
										:disabled="!userFullPermissions"
										:label="`Preview value ${index+1}`"
										small
										:rules="{ max: 255 }"
										@input="updateLinkFieldsRow(index, 'previewValue', $event)"
									/>
								</td>
								<td class="text-left">
									<v-switch
										:input-value="linkField.isEncrypted"
										:disabled="!userFullPermissions"
										dense
										hide-details
										class="ma-0"
										@change="updateLinkFieldsRow(index, 'isEncrypted', $event)"
									/>
								</td>
								<td class="text-left">
									<v-icon
										v-if="linkFields.length > 1 && userFullPermissions"
										small
										@click="onRemoveLinkField(index)"
									>
										mdi-trash-can
									</v-icon>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
				<Message
					v-if="showPreviewWarning"
					type="warning"
				>
					Please note that if the preview value matches to a record in Cassie, any submission will update that record.
				</Message>
				<SectionCard
					v-if="showEncryptionKeyCard && userFullPermissions"
					flat
				>
					<template #title>
						Encryption Key
					</template>
					<template #subtitle>
						Select the encryption key that you will use to encrypt the specified fields, so that Cassie is able to decrypt the information when the link is clicked on.
					</template>
					<template #title-action>
						<TextButton
							v-if="userFullPermissions"
							@click="addEncryptionKey"
						>
							<v-icon>
								mdi-plus
							</v-icon>
							Add Encryption Key
						</TextButton>
					</template>
					<template #body>
						<Dropdown
							:value="encryptionKeyId"
							:disabled="!userFullPermissions"
							:items="encryptionKeys.map(key => ({ value: key.encryptionKeyId, text: key.encryptionKeyName }))"
							label="Select an Encryption Key *"
							rules="required"
							class="cassie-input-width-md"
							@input="updateEncryption('encryptionKeyId', $event)"
						/>
					</template>
				</SectionCard>
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				Behavior
			</template>
			<template #body>
				<div>
					<Toggle
						:value="createStanddakIfNoMatch"
						:disabled="!userFullPermissions"
						label="Create Data Subject if no match found?"
						tooltip-text="For Links with 'Direct' or 'Provide & Prove' Authentication, this will allow the creation of a new Data Subject if the supplied Authentication & Link fields do not match an existing Data Subject."
						dense
						hide-details
						class="ma-0"
						@update:value="updateConfigurationOptions('createStanddakIfNoMatch', $event)"
					/>
					<Toggle
						:value="multiStandDakMatchIsFailure"
						:disabled="!userFullPermissions"
						label="Treat as failure if link matches to more than one data subject in Cassie"
						tooltip-text="If the supplied Authentication & Link fields match multiple Data Subjects, this option will treat the Link as a failure."
						dense
						hide-details
						class="ma-0"
						@update:value="updateConfigurationOptions('multiStandDakMatchIsFailure', $event)"
					/>
					<Toggle
						:value="limitSubmissionsByIpTypeId"
						:disabled="!userFullPermissions"
						label="Limit the number of times a single IP Address can use a link within a set time period"
						tooltip-text="This option allows for limiting to be implemented against the Link.  A maximum number of Preference Page submissions within a given time frame in minutes is required."
						dense
						hide-details
						class="ma-0"
						@update:value="value => {
							updateIpLimitations('limitSubmissionsByIpTypeId', value)
							reset()
						}"
					/>
				</div>
				<div
					v-if="limitSubmissionsByIpTypeId"
					class="d-flex cassie-horizontal-md"
				>
					<TextField
						:value="noOfSubmissionsAllowedFromIp"
						:disabled="!userFullPermissions"
						class="cassie-input-width-sm"
						label="Number of Allowed Submissions *"
						:rules="{greaterThanZero: true, wholeNumber: true, max_value: 86400000}"
						type="number"
						@input="updateIpLimitations('noOfSubmissionsAllowedFromIp', Number($event))"
					/>
					<TextField
						:value="ipLimitPeriodInMins"
						:disabled="!userFullPermissions"
						class="cassie-input-width-sm"
						label="Time Period in Minutes *"
						:rules="{greaterThanZero: true, wholeNumber: true, betweenMinutes: [1, 1439]}"
						type="number"
						@input="updateIpLimitations('ipLimitPeriodInMins', Number($event))"
					/>
				</div>
			</template>
		</SectionCard>
		<AddEncryptionKeyModal
			v-if="showAddEncryptionKeyModal"
			@close="showAddEncryptionKeyModal = false"
			@encryption-key-added="reloadLookUpData"
		/>
		<LinkFieldsHelperModal
			v-if="linkFieldsModalOpen"
			@close="linkFieldsModalOpen = false"
		/>
	</validation-observer>
</template>

<script>
import TextField from '../../../../../shared/components/text-field.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import Message from '../../../../../shared/components/message.vue'
import TextButton from '../../../../../shared/components/text-button.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import AddEncryptionKeyModal from '../../../../../shared/components/add-encryption-key-modal.vue'
import Toggle from '../../../../../shared/components/toggle.vue'
import LinkFieldsHelperModal from './link-fields-helper-modal.vue'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { ACTION_LINK_TYPE, PUBLIC_PORTAL_LINK_TYPE, PREFERENCE_PAGE_LINK_TYPE } from './link-types.js'
export default {
	components: { AddEncryptionKeyModal, Dropdown, TextButton, SectionCard, TextField, LinkFieldsHelperModal, Toggle, Message },
	props: {
		configurationOptions: Object,
		linkFields: Array,
		brandId: Number,
		encryptionKeys: Array,
		userFullPermissions: Boolean,
		linkType: Number,
		contactFields: Array
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			availableFields: [],
			showAddEncryptionKeyModal: false,
			linkFieldsModalOpen: false
		}
	},
	computed: {
		showEncryptionKeyCard () {
			return this.linkFields.some(({ isEncrypted }) => isEncrypted)
		},
		encryptionKeyId () {
			return this.encryption?.encryptionKeyId
		},
		encryption () {
			return this.configurationOptions?.encryption
		},
		createStanddakIfNoMatch () {
			return this.configurationOptions?.createStanddakIfNoMatch
		},
		multiStandDakMatchIsFailure () {
			return this.configurationOptions?.multiStandDakMatchIsFailure
		},
		ipLimitations () {
			return this.configurationOptions?.ipLimitations || {}
		},
		limitSubmissionsByIpTypeId () {
			return this.ipLimitations.limitSubmissionsByIpTypeId
		},
		noOfSubmissionsAllowedFromIp () {
			return this.ipLimitations.noOfSubmissionsAllowedFromIp
		},
		ipLimitPeriodInMins () {
			return this.ipLimitations.ipLimitPeriodInMins
		},
		showPreviewWarning () {
			return this.linkFields.find(
				field => field.previewValue
					? field.previewValue !== ''
					: false
			)
		},
		linkTypeName () {
			switch (this.linkType) {
				case ACTION_LINK_TYPE:
					return 'Action Link'
				case PUBLIC_PORTAL_LINK_TYPE:
					return 'Public Portal Link'
				case PREFERENCE_PAGE_LINK_TYPE:
					return 'Preference Page Link'
				default:
					return 'Link'
			}
		}
	},
	async created () {
		const mappedFields = this.contactFields.map(({ fieldName }) => ({ value: fieldName.toLowerCase(), text: fieldName }))
		if (this.linkType === PREFERENCE_PAGE_LINK_TYPE || this.linkType === ACTION_LINK_TYPE) {
			this.availableFields = mappedFields.sort((a, b) => {
				if (a.value === 'syrenisid') return -1
				if (b.value === 'syrenisid') return 1
				return 0
			})
		} else {
			this.availableFields = mappedFields.filter(({ value }) => value !== 'crc')
		}
	},
	methods: {
		addEncryptionKey () {
			this.showAddEncryptionKeyModal = true
		},
		populateReplaceValue () {
			this.showAddEncryptionKeyModal = true
		},
		updateEncryption (property, value) {
			this.updateConfigurationOptions('encryption', {
				...(this.encryption),
				[property]: value
			})
		},
		updateIpLimitations (property, value) {
			this.updateConfigurationOptions('ipLimitations', {
				...this.ipLimitations,
				[property]: value
			})
		},
		updateConfigurationOptions (property, value) {
			this.$emit('update:configurationOptions', {
				...(this.configurationOptions),
				[property]: value
			})
		},
		updateLinkFieldsRow (index, property, value) {
			const linkFields = JSON.parse(JSON.stringify(this.linkFields))
			const duplicateLinkField = linkFields.find(({ standdakFieldName }) => standdakFieldName === value && property === 'standdakFieldName')
			if (!duplicateLinkField) {
				linkFields[index][property] = value
				if (property === 'standdakFieldName') {
					linkFields[index].replaceValueInLink = `{${value}}`
				}
				this.updateLinkFields(linkFields)
			} else {
				if (linkFields[index][property] === value) {
					return
				}
				this.showSnackbar({ text: `${value} already exists for this Link - Please select another`, color: 'red' })
				this.onRemoveLinkField(index)
			}
		},
		onRemoveLinkField (index) {
			const linkFields = this.linkFields.filter((_, i) => i !== index)
			this.updateLinkFields(linkFields)
		},
		addLinkField () {
			this.updateLinkFields([...(this.linkFields), {}])
		},
		updateLinkFields (linkFields) {
			this.$emit('update:linkFields', linkFields)
		},
		reloadLookUpData () {
			this.$emit('reload-look-up-data')
		},
		usedNameInLinkFields (linkField) {
			const linkFields = this.linkFields?.map(({ friendlyNameInLink, standdakFieldName }) => ({ friendlyNameInLink, standdakFieldName }))
			return linkFields.filter(({ friendlyNameInLink, standdakFieldName }) =>
				friendlyNameInLink === linkField.friendlyNameInLink && standdakFieldName !== linkField.standdakFieldName).map(({ friendlyNameInLink }) => friendlyNameInLink)
				.join(',')
		}
	}
}
</script>
